@import url('https://fonts.googleapis.com/css?family=Petit Formal Script&display=swap');

.App {
  text-align: center;
}
.body {
  background-color: black;
}

.nav-link{
  font-family: Cormorant Garamond;
  color: #ffffff !important;
}

.navbar-toggler-icon {
  color:white !important;
}

.navbar-collapse {
  @media screen and (max-width:768px) {
    background-color: #1a1818ad;
  padding: 5%;
}
  }
  

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.navbar {
  position: absolute;
  top: 0%;
  z-index: 100;
}

.carouselCaption1 {
  display: flex;
    font-family: sofia;
     /* background-color: #0000005c;  */
    width: 100%;
    font-size: 6vw;
    z-index: 100;
    text-align: center;
    align-items: center;
    font-style: italic;
    position: absolute;
    bottom: 45%;
    left: 23%;
    color: white;
    animation: zoom-in-zoom-out 2s ease-out;
    justify-content: space-around;
    height: 33%;
    padding: 0%;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0.5, 0.5);
  }
  /* 50% {
    transform: scale(1.5, 1.5);
  } */
  100% {
    transform: scale(1, 1);
  }
}


.carouselCaption2 {
  display: flex;
  /* background-color: #000; */
  font-family:sofia;
  text-align: center;
  font-size: 5.5vw;
  font-style: italic;
  position: absolute;
  bottom: 46%;
    left: 11%;
  color: white;
  animation: zoom-in-zoom-out 2s ease-out ;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0.5, 0.5);
  }
  100% {
    transform: scale(1, 1);
  }

}

.carouselCaptionTop {
  display: flex;
  font-size: 2vw;
  position: absolute;
  color: white;
  top: 4%;
  right: 10%;
  
}

.carouselCaptionTop2 {
  display: flex;
  font-size: 2vw;
  position: absolute;
  color: white;
  top: 4%;
  left: 10%;
  
}


.buttonCarousel1 {
  background-color: #b14197;
  color: white;
  border-color: #b14197;
  position: absolute;
  bottom: 2%;
  right: 52%;
  font-size: 5vw;
  padding: 3%;
  animation: zoom-in-zoom-out 2s ease-out ;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0.5, 0.5);
  }
  100% {
    transform: scale(1, 1);
  }
 
}

.buttonCarousel2 {
  background-color: #b14197;
  color: white;
  border-color: #b14197;
  position: absolute;
  bottom: 2%;
  right: 3%;
  font-size: 5vw;
  padding: 3%;
  animation: zoom-in-zoom-out 2s ease-out ;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0.5, 0.5);
  }
  100% {
    transform: scale(1, 1);
  }
 
}

.buttonCarousel3 {
  
  position: absolute;
  bottom: 30%;
  left: 15%;
  font-size: 3vw;
 
}

.buttonCarousel4 {
  
  position: absolute;
  bottom: 15%;
  left: 16%;
  font-size: 3vw;
 
}

.buttonCarousel5 {
  
  position: absolute;
  bottom: 22%;
  right: 15%;
  font-size: 3vw;
 
}

.buttonCarousel6 {
  
  position: absolute;
  bottom: 7%;
  right: 16%;
  font-size: 3vw;
 
}

.welcome {
  font-family:sofia;
  text-align: center;
  font-size: 6vw;
  margin-bottom: 5%;
  padding-bottom: 5%;
color: antiquewhite;
overflow: hidden;
background: linear-gradient(90deg, #f7f4f4, #fff, #000);
/* background-repeat: no-repeat; */
background-size: 80%;
animation: animate 5s linear infinite;
-webkit-background-clip: text;
-webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
0% {
  background-position: -500%;
}
100% {
  background-position: 500%;
}
}

.hello {
  @media only screen and (min-width:768px) {
    width: 80%;;
  }
}


.about1 {
  text-align: center;
}

/* .card-text {
  font-family: trirong;
} */

.about2 {
  font-family:Petit Formal Script;
  text-align: center;
  color:white;
  font-size: 6vw;
  @media only screen and (max-width: 768px) {
    font-size: 8vw;
    }
}

.intro1 {
  
  /* line-height: 1.1;
    text-align: center;
    font-family: Italianno !important;
    font-style: normal;
    text-align: center;
    font-size: 7vw;
    letter-spacing: 2px; */
    background-color: #0f0e0e;
    font-family: Cormorant Garamond;
    font-style: italic;
    text-align: center;
    font-size: x-large;
    line-height: 1.5;
  color: antiquewhite;
}

.infoBg {

  /* background-color: #0000008c; */
  /* background-color: #1a18188c; */
  /* background-color: #0e0d0d; */
  background-color: #1a1818;
}

.infoHome {
  /* background-color: #0f0e0e; */
  /* background-color: black; */
  font-family: Cormorant Garamond;
  font-style: italic;
  text-align: center;
  font-size: x-large;
  line-height: 1.5;
color: antiquewhite;

}


.pwuBg {
  background-color: #1a18188c;
}
    /* padding: 5%;

}
  /* font-size: 2vw; */
  /* font-family: Cormorant Garamond; */
  /* @media only screen and (max-width: 992px) {
    font-size: 5vw;
    } */


.facialback {
  /* display: block;  */
  width: 100%;
  position: relative;
  /* z-index: 1; */
}

.rightup {
  position: absolute;
  width: 16%;
  top: 7%;
  left: 40%;
 
}

.leftdown {
  position: absolute;
  width: 16%;
  top: 50%;
  left: 1%;
}

.facialmodel {
  position: absolute;
  width: 45%;
  bottom: 0%;
  left: 10%;
}

.leftup {
  position:absolute ;
  width: 16%;
  top: 5%;
  left: 5%;
}

.rightdown {
  position: absolute;
  width: 15%;
  top: 50%;
  left: 47%;
}

.banner  {
  position: absolute;
  height:  60vw;
  
  top: 0%;
  left:  65%;
  overflow: hidden;
  z-index: 25;
}

.facialbtn {
  position: absolute;
  background-color: #f5efef12;
  /* font-family: 'Lucida Sans'; */
  color: #fff;
  display: inline-block;
  padding: 2% 4%;
  /* font-weight: bold; */
  top: 67.5%;
  left: 67%;
  font-size: 4.5vw;
  line-height: 100%;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0 4px 6px rgba(0, 0, 0, 0.23), 0 0 5px #aaa inset;


} 

.facialTouch {
  width: 9%;
    position: absolute;
    bottom: 0%;
    right: 9%;
    z-index: 100;
    animation: zoomIn 3s infinite ;
}

.facialbtndt{
  position: absolute;
    background-color: #f5efef12;
    /* font-family: 'Lucida Sans'; */
    color: #f5eeee;
    display: inline-block;
    padding: 2% 4%;
    top: 37%;
    left: 33%;
    font-size: 2vw;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0 4px 6px rgba(0, 0, 0, 0.23), 0 0 5px #aaa inset;
}

.embroBtn {
  width: 16vw;
			height: 16vw;
			/* padding: 2% 4%; */
      background-color: #222020;
      border: 1vw solid #B4943F;
			border-radius: 60%;
      color: #B4943F;
      font-family: serif;
  font-size: 3vw;
  line-height: 1.2;
			text-align: center;
      position: absolute;
      top: 58%;
      left: 43%;
}

.embroBtnDt {
  width: 10vw;
    height: 10vw;
    /* padding: 2% 4%; */
    background-color: #222020;
    border: 1vw solid #B4943F;
    border-radius: 60%;
    color: #B4943F;
    font-family: serif;
    font-size: 2.4vw;
    line-height: 1.1;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 60%;
    z-index: 100;
}
.embroBtn2 {
  width: 15vw;
  height: 15vw;
  /* padding: 2% 4%; */
  background-color: #222020;
  border: 1vw solid #B4943F;
  border-radius: 60%;
  color: #B4943F;
  font-family: serif;
  font-size: 2.5vw;
  line-height: 1.2;
  text-align: center;
  position: absolute;
  top: 20%;
  left: 53%;

}.embroBtn2Dt {
  width: 10vw;
    height: 10vw;
    /* padding: 2% 4%; */
    background-color: #222020;
    border: 1vw solid #B4943F;
    border-radius: 60%;
    color: #B4943F;
    font-family: serif;
    font-size: 2vw;
    line-height: 1.2;
    text-align: center;
    position: absolute;
    top: 48%;
    left: 15%;
}

.embroInfo {
  font-family: Petit Formal Script;
  position: absolute;
  color: #B4943F;
  font-size: 3.1vw;
  top: 48%;
  left: 3%;
}

.embroInfoDt {
  font-family: Petit Formal Script;
    position: absolute;
    color: #B4943F;
    font-size: 2vw;
    top: 20%;
    left: 3%;
}

.eyeTouch {
  position: absolute;
  right: 43%;
  bottom: 9.5%;
  width: 8.5%;
  z-index: 100;
  animation: zoomIn 3s infinite ;
}

.sareeTouch {
  bottom: 24%;
  position: absolute;
  right: 3%;
  width: 9%;
  animation: zoomIn 3s infinite ;

}

@keyframes zoomIn {
  0% {
    transform: scale(1,1);
  }
  50% {
    transform: scale(1.2,1.2);
  }
  100% {
    transform: scale(1,1);
  }
}

.sareeRight {
  overflow: hidden;
    position: absolute;
    width: 35%;
    bottom: 52%;
    left: 60%;
}

.sareeLeft {
  overflow: hidden;
    position: absolute;
    width: 35%;
    bottom: 52%;
    left: 5%;
}

.sareeCenter {
  position: absolute;
    width: 35%;
    bottom: 22%;
    left: 32.5%;
}

.sareeCap {
  position: absolute;
  font-family:serif;
  font-size: 6vw;
  text-align: center;
  bottom: 3%;
  left: 17%;
}

.sareeButtonE {
  background-color: #495057;
    color: white;
    border-color: #495057;
    position: absolute;
    bottom: 26%;
    right: 5%;
    font-size: 4vw;
    padding: 1%;
    padding-right: 8%;
    padding-left: 4%;
}

.sareeInfo {
  font-family: Petit Formal Script;
  /* Sofia|Pacifico|Trirong|Italianno|Petit Formal Script|montserrat */
  position: absolute;
  text-align: center;
  align-items: center;
  top: 2%;
    left: 38%;
    font-size: 4vw;
    line-height: 0.9;

}

.sareeInfoDt {
  font-family: Petit Formal Script;
    color: antiquewhite;
    position: absolute;
    text-align: center;
    align-items: center;
    top: 58%;
    left: 25%;
    font-size: 3vw;
    line-height: 1.7;
}

.title {
  font-family:sofia;
  text-align: center;
  font-size: 2.5vw;
  padding: 1%;
  /* margin-bottom: 5%;
  padding-bottom: 5%; */
color: antiquewhite;
@media only screen and (max-width:768px) {
  font-size: xx-large;
}
}

.feedBackBg {
@media screen and (min-width:768px) {
  background-image: url('./cfbDt.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
}


  


.facialCard {
  display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
}

.facialIng {
  margin-bottom: 0%;
  padding-top: 2%;
  overflow: hidden;
  font-family:Cormorant Garamond !important ;
  font-style: italic;
  @media screen and (min-width:768px) {
    width: 50%;
  }
}

.facialBanner {
  overflow: hidden;
  @media screen and (max-width: 768px) {
    margin-top: 20%;
    
  }
  
}

.facialChoice {
  display: flex;
    flex-direction: column;
    align-items: center;
}

.fb1 {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 60vw;
  height: 39vw;
  bottom: 1%;
  font-family:Cormorant Garamond !important ;
  font-size: 4.5vw;
  right: 20%;
  color: #fff;
  background-color: #00000008;
  padding: 2%;
  border: none;
}

.feedBackBgMb {

  @media screen and (max-width:768px) {
    background-image: url('./feedbackbg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.fbTitle {
  color: antiquewhite;
  font-family: sofia;
  position: absolute;
  font-size: 8vw;
  right: 25%;
  margin-top: 4%;
}


.facialBody {
  @media screen and (min-width:768px) {
    background-color: #0f0e0e;
  }
}

.fb1Dt {
  position: absolute;
    display: flex;
    justify-content: center;
    width: 25vw;
    height: 42vw;
    bottom: 0%;
    font-family:Cormorant Garamond !important ;
    font-size: 2vw;
    right: 32.5%;
    color: #fff;
    background-color: #00000008;
    padding: 2%;
}

.msTitle {
  
   font-family: Cormorant Garamond;
  font-style: italic;
  text-align: center;
  font-size: large;
  line-height: 1.5;
color: antiquewhite;
padding: 2%;
}

.whyUs {
  background-color: #0f0e0e;
  font-family: Cormorant Garamond;
  font-style: italic;
  text-align: center;
  font-size: larger;
  line-height: 1.5;
color: antiquewhite;
padding: 8%;
padding-top: 5%;
}

.embroBody {
   
    @media screen and (min-width:768px) {
      background-color: #0f0e0e;
      padding-left: 8%;
      padding-right: 8%;
    }
   
  
}
.wmInfo {
  background-color: #0f0e0e;
  font-family: Cormorant Garamond;
  font-style: italic;
  text-align: center;
  font-size: large;
  line-height: 1.5;
color: antiquewhite;
padding: 2%;
}

.wPackage {
  background-image: url('./model6.jpg');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  font-family:Petit Formal Script !important; 
  font-style: normal;
  text-align: center;
  font-size: medium;
  line-height: 1.4;
color: antiquewhite;
padding: 5%;
}

.wpCard {
  background-color: transparent;
  height: 100%;
  object-fit: cover;
}

.wMenu {
  background-color: rgb(4 4 5 / 60%);
  font-family:Petit Formal Script !important; 
  font-style: normal;
  text-align: center;
  font-size: medium;
  /* @media only screen and (min-width: 768px) {
    font-size: large;
    width: 50%;
  } */
}

.wMenuDt{
  background-color: rgb(4 4 5 / 60%);
  font-family:Petit Formal Script !important; 
  font-style: normal;
  text-align: center;
  font-size: 1.5vw;
  color: antiquewhite;
}

.whyUsDt {
  background-color:black;
  font-family: Cormorant Garamond !important; 
  font-style: normal;
  text-align: center;
  font-size: 1.5vw;
  color: antiquewhite;
}

.accordion {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button{
  background-color: rgb(4 4 5 / 0%);
  color: antiquewhite;
  font-family: Cormorant Garamond !important; 
}

.accordian-button::after {
  color: white;
}

.accordion-button:not(.collapsed) {
  /* background-color: rgb(4 4 5 / 70%); */
  background-color: rgb(13 13 182 / 60%);
  color: antiquewhite;
  font-family: Cormorant Garamond !important; 
}

.accordion-item {
  /* background-color: rgb(4 4 5 / 60%); */
  background-color: #171717;
  font-family: Cormorant Garamond !important; 
  font-size: larger;
  color: antiquewhite;
}

.accHeader {
  background-color:rgb(4 4 5 / 60%) ;
  font-family: Cormorant Garamond !important; 
  
}

.faq {
@media only screen and (min-width: 768px) {
  padding-left: 10%;
  padding-right: 10%;
}
  
}

.bookMu {
  background-color:rgb(4 4 5 / 60%) ;
  display: flex;
  flex-direction: column;
  
}

.gallery{
  text-align: center;

}

.facialTitle{
  position: absolute;
    width: 100%;
    bottom: 0%;
    /* right: 0%; */
  
}

.formButton {
font-family: Cormorant Garamond;
  font-style: italic;
  text-align: center;
  font-size: 5vw;
color: antiquewhite;
padding: 3%;
@media screen and (min-width:768px) {
  font-size: larger;
}
}

.formStyle {
  background-color: #0f0e0e;
  font-family: Cormorant Garamond;
  font-style: italic;
  text-align: center;
  font-size: 5vw;
  line-height: 1.5;
color: antiquewhite;
padding: 3%;
@media screen and (min-width:768px) {
  font-size: larger;
}
}

.facialUs {
  background-color: #0f0e0e;
  font-family: Cormorant Garamond;
  font-style: italic;
  text-align: center;
  font-size: larger;
  line-height: 1.5;
color: antiquewhite;
padding: 3%;
padding-top: 5%;
}

.verticalLine {
  position: relative;
  background: #c28210;
  height: 7em;
  left: 50%;
  width: 1px;
  margin-bottom: 0%;
}
.circleBar {
  display: flex;
  position: relative;
  height: 2em;
  width: 2em;
  background-color: #c28210;
  border-radius: 100%;
  color: white;
  justify-content: center;
  align-items: center;
  margin-bottom: 0%;
}



.steps {
  display: flex;
  align-items: flex-start;
  /* padding: 8%; */
}

.steps2 {
  display: flex;
  align-items: center;
  /* padding: 8%; */
}

.stepsInfo {
  font-family: Cormorant Garamond;
  /* font-style: italic; */
  /* text-align: center; */
  font-size: larger;
  line-height: 1.5;
color: antiquewhite;
padding: 1%;
    margin-left: 2%;
}

.stepsNumber {
  display: flex;
    align-items: flex-start;
}

.facialPriceC {
  display: flex;
    justify-content: center;
}

.facialPrice {
 
  font-family: Cormorant Garamond;
  /* font-style: italic; */
  text-align: center;
  font-size: xx-large;
  line-height: 1.5;
color: antiquewhite;
width: 100%;
/* @media screen and (min-width:768px) {
  width: 50%;
} */
}


.embroEg{
  display: flex;
    justify-content: space-between;
    margin-top: 3%;
}

.pleating {
  overflow: hidden;
  
  height: calc(100% - 1px);
  position: fixed;
  overflow-y: scroll;
}

.pleatBg {
  
  display: flex;

  background-image:url("./spbg.jpg");
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
   justify-content: center;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat; 
}

.pleatTopBg {

  display: flex;

  background-image:url("./spbg2.jpg");
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  /* display: block;
  background-image:url("./spbg2.jpg");
  width: 100vw;
  height: 120vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center; 
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat; */
  
}

/* .sareeTop {
  overflow: scroll;
} */

.buffer {
  width: 100%;
  min-height: 30vh;
  background-color: #ffe41b;
}

.pleat0 {
  min-height: 300%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 0;
  
}

.sticky {
  
  margin-top: -100vh;
  width: 100%;
  height: 100vh;
  background: linear-gradient(45deg, red, blue);
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 0;
}

.section {
  position: relative;
  background-color: #0f0e0e;
  width: 100%;
  min-height: 100vh;
  /* padding: 3em calc(20vw - 70px); */
  padding: 6%;
  padding-top: 13%;
  z-index: 1;
 
  font-family: Cormorant Garamond;
  font-style: italic;
  text-align: center;
  /* font-size: xxx-large; */
  line-height: 1.8;
color: antiquewhite;
/* padding: 3%;
padding-top: 5%; */
}

.header {
  position: relative;
  /* margin-top: -100vh; */
  width:100%;
  height:70vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  z-index: 2
}

.headerDt {
  @media screen and (min-width:768px) {
    margin-top: 15%; 
  }
}

.header-text {
  height: auto;
  padding: 0px 20px;
  padding-left: calc(20vw - 70px);
  margin-bottom: 40px;
  color: white;
  background: rgba(16, 32, 48, 0.5)
}

.sareePleatCon {
  width:80%;
  @media screen and (min-width:768px) {
    width: 40vw;
  }
}

.sareeButton {
  position: absolute;
  width: 80%;
  margin-top: 40%;
  background-color: #B14197;
  font-size: xx-large;
  font-family: Cormorant Garamond;
  color: white;
}



.pleat1Dt {
  @media screen and (min-width:768px) {
    background-image: url("./spbgDt1.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-self: center;
  }
 
}

.pleat2 {
  background-image: url("./spbg2.jpg");
  background-size: cover;
  display: flex;
  justify-self: center;
  /* margin-top: -100vh;
  width: 100vw; */
  /* height: 100vh; */
  /* background: linear-gradient(45deg, red, blue); */
  /* position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: -2; */
}

.pleat1 {
  @media screen and (max-width:768px) {
  background-image: url("./spbg1.jpg");
  background-size: cover;
  display: flex;
  justify-self: center;

  } 
}
.pleat3 {
  background-image: url("./spbg3.jpg");
  background-size: cover;
  /* margin-top: -100vh;
  width: 100vw; */
  /* height: 100vh; */
  /* background: linear-gradient(45deg, red, blue); */
  /* position: -webkit-sticky; 
  position: sticky;
  top: 0;
  z-index: 0; */
}

.sareeCard {
  display: flex;
  justify-content: center;
}

.footerLogo {
  /* width: 50%; */
}

.breakLine {
  background: #B14197;
    height: 1px;
    position: relative;
    width: 100%;
    margin-bottom: 0%;
}

.FooterTitle{
  padding:2%;
  margin-bottom: 0%;
  text-align: center;
  position: absolute;
  background-color: #0f0e0e;
}

.footerBreak {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  border: solid white
}

.pageNotFound {
  font-family: Cormorant Garamond;
  text-align: center;
  color: white;
  padding: 28%;
  font-size: xx-large;
  @media screen and (max-width:768px) {
    padding: 20%;
    margin-top: 30%;
    margin-bottom: 30%;
  }
 
}